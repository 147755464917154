import React from 'react'

const Header = () => (
  <div className="container">
      <header className="header lower-top-padding">
          <div className="container">
              <div className="navigation">
                  <a href="https://ddzone.xyz/" className="logo">
                      <img src="./images/logo.png" alt="DD Zone " width="80px" height="47px"/>
                  </a>
                  <div className="nav-right-side">
                      <ul>
                          <li><a href="https://ddzone.xyz/myplan">Access my plan</a></li>
                          <li><a href="https://ddzone.xyz/reviews">Reviews</a></li>
                          <li><a href="https://ddzone.xyz/blog">Blog</a></li>
                      </ul>
                  </div>
              </div>
          </div>
      </header>
  </div>
);

export default Header
