import * as React from "react"
import Layout from '../components/layout'

// markup
// Everything You Need to Put Your Business in Cloud
// Everything You Need to Start Selling Online and In-Store
const IndexPage = () => {
  return (
    <Layout>
      <main>
          <section className="hero">
              <div className="container">
                  <div className="mobile-navigation-content">
                      <div className="header-line">
                          <a href="https://ddzone.xyz" className="logo"><img src="./images/logo.png" alt=""/></a>
                      </div>
                      <ul>
                          <li><a href="https://ddzone.xyz/myplan">Access my plan</a></li>
                          <li><a href="https://ddzone.xyz/reviews">Reviews</a></li>
                          <li><a href="https://ddzone.xyz/blog">Blog</a></li>
                      </ul>
                  </div>
                  <div className="mobile-navigation-content-overlay"></div>
                  <div className="row hero-content">
                      <div className="col-xl-6 text">
                          <h1>
                              Everything You Need to Start Selling Online and In-Store
                          </h1>
                          <div className="gender-description">
                              <h5>
                                  &nbsp;
                              </h5>
                              <div className="button-wrapper gender-buttons">
                                  <button className="btn-female btn" data-gender="female">
                                      <span className="icon"><i className="female"></i></span>
                                      Create a Account
                                  </button>
                                  {/*
                                  <button className="btn-male btn" data-gender="male">
                                      <span className="icon"><i className="male"></i></span>
                                      Men click here
                                  </button>
                                  */}
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </section>


      </main>
    </Layout>
  )
}

export default IndexPage
