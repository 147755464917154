import React from 'react'
import {Link} from 'gatsby'

const Footer = () => (

    <footer className="footer-big">
        <div className="container">
            <div className="footer-big__primary">
                <div className="footer-big__columns">
                    <div className="footer-big__column footer-big__column--logo">
                        <div className="footer-big__column-inner">
                            <img src="./images/logo.png" alt="Footer logo" width="60px" height="35px" className="footer-big__logo"/>
                                <div className="footer-big__promotion footer-big__promotion--desktop">
                                    <div className="footer-big__smarter">
                                        <span className="footer-big__smarter-title">Customize and Extend</span>
                                        <p className="footer-big__smarter-paragraph">From subscriptions to parking cars, DD Zone Platform is fully customizable.
                                            Add features and extend the functionality of your store with official extensions from the Marketplace.</p>
                                    </div>
                                    <div className="footer-big__socials">
                                        <div className="footer-big__social-item">
                                            <a href="https://www.facebook.com/quantic.cloud.tech" target="_blank">
                                                <img src="./images/footer-social-fb.svg" alt="Social icon facebook" width="24px" height="24px"/>
                                            </a>
                                        </div>
                                        <div className="footer-big__social-item">
                                            <a href="https://twitter.com/quantic_cloud/" target="_blank">
                                                <img src="./images/footer-social-ig.svg" alt="Social icon instagram " width="24px" height="24px"/>
                                            </a>
                                        </div>
                                        <div className="footer-big__social-item">
                                            <a href="https://www.linkedin.com/company/quantic-cloud" target="_blank">
                                                <img src="./images/footer-social-yt.svg" alt="Social icon youtube" width="24px" height="24px"/>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                        </div>
                    </div>
                    <div className="footer-big__column footer-big__column--nav">
                        <div className="footer-big__column-inner">
                            <div className="footer-big__nav footer-big__nav--toggle">
                                <div className="footer-big__nav-title">For business</div>
                                <ul className="footer-big__nav-list">
                                    <li className="footer-big__nav-item"><a href="https://ddzone.xyz/blog" className="footer-big__nav-link">Partners</a></li>
                                    <li className="footer-big__nav-item"><a href="https://ddzone.xyz/reviews" className="footer-big__nav-link">Pricing</a></li>
                                    <li className="footer-big__nav-item"><a href="https://ddzone.xyz/myplan" className="footer-big__nav-link">Support</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="footer-big__column footer-big__column--nav">
                        <div className="footer-big__column-inner">
                            <div className="footer-big__nav footer-big__nav--toggle">
                                <div className="footer-big__nav-title">About DD Zone</div>
                                <ul className="footer-big__nav-list">
                                    <li className="footer-big__nav-item">
                                        <a href="https://ddzone.xyz/hc" className="footer-big__nav-link" target="_blank">Support</a>
                                    </li>
                                    <li className="footer-big__nav-item">
                                        <a href="https://ddzone.xyz/contacts" className="footer-big__nav-link">Contact us</a>
                                    </li>
                                    <li className="footer-big__nav-item">
                                        <a href="https://ddzone.xyz/data-protection-policy" className="footer-big__nav-link">Data protection policy</a>
                                    </li>
                                    <li className="footer-big__nav-item">
                                        <a href="https://ddzone.xyz/general-conditions" className="footer-big__nav-link">General conditions</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="footer-big__column footer-big__column--nav">
                        <div className="footer-big__column-inner">
                            <div className="footer-big__nav footer-big__nav--toggle">
                                <div className="footer-big__nav-title">Earn money</div>
                                <ul className="footer-big__nav-list">
                                    <li className="footer-big__nav-item">
                                        <a href="https://ddzone.xyz/affiliates" className="footer-big__nav-link" target="_blank">Affiliates</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="footer-big__column footer-big__column--get-app">
                        <div className="footer-big__column-inner">
                            <div className="footer-big__nav footer-big__nav--get-app">
                                <div className="footer-big__nav-title">Get the app</div>
                                <ul className="footer-big__nav-list">
                                    <li className="footer-big__nav-item">
                                        <a href="https://apps.apple.com/app/dd-digital/id1599873614" className="footer-big__nav-link" target="_blank">
                                            <img src="./images/footer-appstore.webp" alt="App store" width="164px" height="48px"/>
                                        </a>
                                    </li>
                                    <li className="footer-big__nav-item">
                                        <a href="https://play.google.com/store/apps/details?id=digital.dd" className="footer-big__nav-link" target="_blank">
                                            <img src="./images/footer-googleplay.webp" alt="Google play" width="164px" height="48px"/>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-big__promotion footer-big__promotion--mobile">
                    <div className="footer-big__smarter">
                        <span className="footer-big__smarter-title">Customize and Extend</span>
                        <p className="footer-big__smarter-paragraph">From subscriptions to parking cars, DD Zone Platform is fully customizable.
                            Add features and extend the functionality of your store with official extensions from the Marketplace.</p>
                    </div>
                    <div className="footer-big__socials">
                        <div className="footer-big__social-item">
                            <a href="https://www.facebook.com/quantic.cloud.tech" target="_blank">
                                <img src="./images/footer-social-fb.svg" alt="Social icon facebook" width="24px" height="24px"/>
                            </a>
                        </div>
                        <div className="footer-big__social-item">
                            <a href="https://twitter.com/quantic_cloud" target="_blank">
                                <img src="./images/footer-social-ig.svg" alt="Social icon instagram" width="24px" height="24px"/>
                            </a>
                        </div>
                        <div className="footer-big__social-item"><a href="https://www.linkedin.com/company/quantic-cloud" target="_blank">
                                <img src="./images/footer-social-yt.svg" alt="Social icon youtube" width="24px" height="24px"/>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-big__secondary">
                <span className="footer-big__copyright">Copyright © 2022 DD Zone, Inc., All rights reserved.</span>
            </div>
        </div>
    </footer>
);

export default Footer
